@import "src/theme/media.scss";

.container {
  overflow: visible !important;
  max-width: unset;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: 700px;
  overflow: auto;

  @media #{$tablet} {
    width: 450px;
  }

  @media #{$mobile} {
    width: 95vw;
  }
}

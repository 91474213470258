@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  width: 815px;

  @media #{$tablet} {
    justify-content: center;
    width: 650px;
  }

  @media #{$mobile} {
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }
}

.item {
  width: 185px;

  @media #{$tablet} {
    width: 135px;
  }

  @media #{$mobile} {
    padding-left: 25px;
    border-radius: 10px;
    background-color: $primary-variant-2;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;
  }
}

.itemSelected {
  @media #{$mobile} {
    background-color: lighten($supplemental-variant-1, 25%);
  }
}

.label {
  margin: 0 auto !important;
  width: fit-content !important;

  @media #{$mobile} {
    color: $primary-variant-1 !important;
    margin: 0 !important;
  }
}

.iconContainer {
  border-radius: 5px;
  background-color: $primary-variant-2;
  height: 190px;
  width: 185px;
  padding: 45px;
  margin-bottom: 25px;
  cursor: pointer;

  @media #{$tablet} {
    height: 135px;
    width: 135px;
  }

  @media #{$mobile} {
    padding: 15px;
    height: 60px;
    width: 60px;
    min-height: 60px;
    min-width: 50px;
    margin-bottom: unset;
  }
}

.iconWrapper {
  position: relative;
  transition: all 0.2s ease-in-out;
  align-items: center;
  justify-content: center;
  display: flex;

  width: 100%;
  height: 100%;

  & svg {
    height: 70px;
  }

  @media #{$tablet} {
    margin-bottom: unset;
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
  }

  @media #{$mobile} {
    margin-right: 15px;
    margin-bottom: unset;
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
  }
}

.selected {
  background-color: lighten($supplemental-variant-1, 25%);
}

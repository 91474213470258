html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html {
  height: 100%;
  overflow: auto; 
}

body {
  height: 100%; 
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

:root {
  --swiper-navigation-color: #fff;
}

@font-face {
  font-family: "lic_sanomat";
  font-display: swap;
  src: url(/fonts/sanomat-medium.woff2) format("woff2"),
    url(/fonts/sanomat-medium.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "lic_sanomat";
  font-display: swap;
  src: url(/fonts/sanomat-semibold.woff) format("woff");
  font-weight: 600;
}
